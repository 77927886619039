import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LucidModal from '@/components/LucidModal';
import { useTranslation } from '@/hooks/translation';
import { Earn } from '@/icons';
import styles from './surveyPartnersWithoutImage.module.scss';

export default function SurveyPartnersWithoutImage({ img, url, className, onClick, ...extraProps }) {
  const [showLucidModal, setShowLucidModal] = React.useState(false);
  const { t } = useTranslation();

  const onHide = () => {
    setShowLucidModal(false);
  };

  const handleClick = () => {
    onClick?.();
    setShowLucidModal(true);
  };

  return (
    <div className='w-100'>
      <div
        className={cx(styles.surveyPartnersWithoutImage, "d-flex align-items-center", className)}
        onClick={handleClick}
      >
        <div className="flex-grow-1">
          <div className={cx(styles.header, "d-flex align-items-center justify-content-between my-1")}>
            <p>{t('surveys.copy')}</p>
            <div className={styles.iconWrapper}>
              <Earn className={styles.icon} />
            </div>
          </div>
          <div className={cx(styles.content, "d-flex align-items-center justify-content-between mt-3 mb-1")}>
            <p>{t('surveys.reward')}</p>
            <div>
              <div className={cx(styles.ctaPill, "d-flex align-items-center justify-content-center")}>{t('surveys.cta')}</div>
            </div>
          </div>
        </div>
      </div>
      <LucidModal
        show={showLucidModal}
        onHide={onHide}
        href={url}
        {...extraProps}
      />
    </div>
  );
}

SurveyPartnersWithoutImage.propTypes = {
  img: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SurveyPartnersWithoutImage.defaultProps = {
  img: "https://images.contentstack.io/v3/assets/bltd488044897c9abc0/bltadd64fbc13318432/600aa41d5ad9610f6cb77bbc/lucid_logo.png",
};

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SurveyLink from '@/components/surveys/SurveyLink';
import { useTranslation } from '@/hooks/translation';
import { ChevronRight, Earn, Time } from '@/icons';
import { localeType, surveyType } from '@/types';
import { asCurrency } from '@/utilities/string';
import { surveyTitle } from '@/utilities/survey';
import styles from './surveyCardMobile.module.scss';

export default function SurveyCardMobile(props) {
  const { index, survey, user, locale, singleSurvey } = props;
  const { t } = useTranslation();
  const surveyPosition = singleSurvey ? '0' : `${index + 1}`;

  return (
    <div className={cx(styles.wrapper, "mb-4")}>
      <SurveyLink survey={survey} user={user} surveyPosition={surveyPosition}>
        <h6>{surveyTitle(survey, locale)}</h6>
        <div className='d-flex mt-3'>
          <div className={cx(styles.column, "d-flex")}>
            <div className={styles.iconWrapper}>
              <Earn className={styles.icon} />
            </div>
            <div className={cx(styles.columnContent, "d-flex flex-column")}>
              <span>{t('home.survey_card.you_can_earn')}</span>
              <p>
                {survey.survey_study_incentive
                  ? asCurrency(survey.survey_study_incentive)
                  : asCurrency(survey.incentive)
                }
              </p>
            </div>
          </div>
          <div className={cx(styles.column, "d-flex")}>
            <div className={styles.iconWrapper}>
              <Time className={styles.icon} />
            </div>
            <div className={cx(styles.columnContent, "d-flex flex-column")}>
              <span>{t('home.survey_card.time_taken')}</span>
              <p>{survey.interview_length || 0} min</p>
            </div>
          </div>
        </div>
        <ChevronRight className={styles.chevronRight} />
      </SurveyLink>
    </div>
  );
}

SurveyCardMobile.propTypes = {
  index: PropTypes.number,
  survey: surveyType,
  user: PropTypes.object,
  locale: localeType,
  singleSurvey: PropTypes.bool,
};

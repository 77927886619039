import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import StandardCard from '@/components/cards/StandardCard';
import SurveyLink from '@/components/surveys/SurveyLink';
import { useTranslation } from '@/hooks/translation';
import { localeType, surveyType } from '@/types';
import { asCurrency } from '@/utilities/string';
import { surveyTitle } from '@/utilities/survey';
import styles from './surveyCard.module.scss';

export default function SurveyCard({ index, className, locale, survey, user, surveyCardClassName, singleSurvey, ...extraProps }) {
  const { t } = useTranslation();
  const surveyPosition = singleSurvey ? '0' : `${index + 1}`;

  return (
    <div className={cx(className, styles.cardContainer)}>
      <SurveyLink survey={survey} user={user} surveyPosition={surveyPosition}>
        <StandardCard backgroundColor="#5C7AFF" className={cx(styles.card, surveyCardClassName)} {...extraProps}>
          <div className={`${styles.content} d-flex flex-column justify-content-between`}>
            <div className={`${styles.info} mb-auto`}>
              <h4>{surveyTitle(survey, locale)}</h4>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span className={styles.incentive}>{asCurrency(survey.incentive)}</span>
              {survey.interview_length && (
                <span className={styles.length}>{survey.interview_length} {t("home.survey_card.minutes")}</span>
              )}
            </div>
          </div>
        </StandardCard>
      </SurveyLink>
    </div>
  );
}

SurveyCard.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  survey: surveyType,
  user: PropTypes.object,
  locale: localeType,
  surveyCardClassName: PropTypes.string,
  singleSurvey: PropTypes.bool,
};

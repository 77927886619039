import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import SurveyLink from '@/components/surveys/SurveyLink';
import { useTranslation } from '@/hooks/translation';
import { ChevronRight} from '@/icons';
import { MoneyBag, Duration } from '@/icons/earn';
import { localeType, surveyType } from '@/types';
import { asCurrency } from '@/utilities/string';
import { surveyTitle } from '@/utilities/survey';
import styles from './surveyCardList.module.scss';

export default function SurveyCardList({ index, className, locale, survey, user }) {
  const { t } = useTranslation();
  const surveyPosition = `${index + 1}`;

  return (
    <div className={className}>
      <SurveyLink survey={survey} user={user} surveyPosition={surveyPosition}>
        <div className={styles.content}>
          <div className={styles.leftCol}>
            <div className={`${styles.info}`}>
              <h4>{surveyTitle(survey, locale)}</h4>
            </div>

            <div className="d-flex align-items-center">
              <MoneyBag className='me-1' />
              <span className={styles.incentive}>  {asCurrency(survey.incentive)}</span>

              {survey.interview_length && (
                <>
                  <div className={styles.verticalLine} />
                  <Duration className='me-1' />
                  <span className={styles.length}>{survey.interview_length} {t("home.survey_card.minutes")} </span>
                </>
              )}
            </div>
          </div>

          <div className={styles.rightCol}>
            <Button className={styles.surveyCta}>
              {t("earn.start_survey_cta")} <ChevronRight className='ms-1' />
            </Button>
          </div>
        </div>
      </SurveyLink>
    </div>
  );
}

SurveyCardList.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  survey: surveyType,
  user: PropTypes.object,
  locale: localeType,
};

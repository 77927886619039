import baseAuthAdminQuery from './baseAuthAdminQuery';
import baseUnauthAdminQuery from './baseUnauthAdminQuery';

export async function getSurveyCenterData() {
  const response = await baseAuthAdminQuery({
    path: '/survey_center',
    method: 'get',
  });

  return response?.data;
}

export async function getUnauthSurvey() {
  const response = await baseUnauthAdminQuery({
    path: '/surveys',
    method: 'get',
  });

  return response?.data;
}

export async function getStudyUrl(studyId) {
  const response = await baseAuthAdminQuery({
    path: `/survey_center/open_study/${studyId}`,
    method: 'get',
  });

  return response?.data;
}

export async function getLucidUrl(lang) {
  const response = await baseAuthAdminQuery({
    path: `survey_center/open_lucid/${lang}`,
    mehtod: 'get',
  });

  return response?.data;
}

export async function getLucidSurveyUrl(surveyId) {
  const response = await baseAuthAdminQuery({
    path: `survey_center/open_lucid_study/${surveyId}`,
    method: 'get',
  });

  return response?.data;
}

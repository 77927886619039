import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Button from '@/components/Button';
import Option2SignUp from '@/components/earn/SignUpBanner/Option2SignUp';
import PreferredSignUp from '@/components/earn/SignUpBanner/PreferredSignUp';
import Modal from '@/components/Modal';
import { useTranslation } from '@/hooks/translation';
import { CloseCircle } from '@/icons';
import { isFeatureEnabled } from '@/utilities/features';
import styles from './incentiveModal.module.scss';

export default function IncentiveModal({ internal, inline, onHide, show }) {
  const router = useRouter();
  const { t } = useTranslation();
  const allowPaidSurveys = isFeatureEnabled('allow_paid_surveys_for_all_users');
  const wantToEarnText = allowPaidSurveys ? t('earn.want_to_earn_with_paid_surveys') : t('earn.want_to_earn');

  const handleHide = () => {
    if (onHide) {
      onHide();
    }

    if (internal) {
      router.back();
    } else if (!inline) {
      router.push('/earn');
    }
  };

  return (
    <Modal
      close={false}
      contentClassName={styles.modalContent}
      dialogClassName={styles.incentiveModal}
      fullscreen
      onHide={handleHide}
      show={show}
    >
      <Col className="align-items-center justify-content-center text-center mb-5">
        <div className="d-flex justify-content-end">
          <Button iconButton className={styles.btn} onClick={handleHide}><CloseCircle /></Button>
        </div>
        <h1 className="mt-3">{wantToEarnText}</h1>
        <h2>{t('earn.way_to_pay')}</h2>
        <div className="d-flex flex-column align-items-center justify-content-between text-start">
          <Col xs><PreferredSignUp /></Col>
          <Col xs className='mt-3'><Option2SignUp /></Col>
        </div>
      </Col>
    </Modal>
  );
}

IncentiveModal.propTypes = {
  internal: PropTypes.bool,
  inline: PropTypes.bool,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};
